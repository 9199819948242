import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Card, Form, InputGroup } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Upload, Spin, Modal, message,Tabs } from 'antd';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faPlus} from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";
import Choices from "./Choices";
import Prices from "./Prices";

import { RoutesPaths } from "../routes";
const { TabPane } = Tabs;

export default () => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [stake, setStake] = useState(20);
  const [banner, setBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState("");
  const [configOptions, setConfigOptions] = useState("");
  const [loading, setLoading] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    if(params.id) getJackpotCongigurations();
    getCongigOptions();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getJackpotCongigurations = () => {
    setLoading(true);
    let Id=params.id;
    if(mainconfigs.id) Id=mainconfigs.id;
    LModel.find(`octagon/superjackpot/jackpot/${Id}`,null,null).then((response) => {
      if (response.data) {
        setMainConfigs(response.data);

        setLoading(false);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setStake(response.data.stake);
        setStartDate(response.data.start_time);
        setEndDate(response.data.end_time);
        setStatus(response.data.status);

        let fileName = (response.data.banner.split("/"));
        let bannerDetail = { uid: response.data.id, name: fileName[fileName.length - 1], status: 'done', url: response.data.banner, isCurrentImg: true };
        setBanner([bannerDetail]);
       setFileList([bannerDetail]);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
        if(params.id && mainconfigs.id && mainconfigs.event_choices.length>0 && mainconfigs.prizes.length>0) setStatus(response.data.superjackpot_status[0][0]);
        // else if(params.id && !mainconfigs.id) setStatus(response.data.superjackpot_status[0][0]);
        else setStatus(response.data.superjackpot_status[1][0]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  createNewJackpot =()=>{
    navigate('/JackpotConfig');
    setMainConfigs([]);
    setStake(20);
    setTitle("");
    setDescription("");
    // setStartDate(null);
    setEndDate(null);
    if(params.id && mainconfigs.id && mainconfigs.event_choices.length>0 && mainconfigs.prizes.length>0) setStatus(configOptions.superjackpot_status[0][0]);
    // else if(mainconfigs) setStatus(configOptions.superjackpot_status[1][0]);
    else setStatus(configOptions.superjackpot_status[1][0]);
    setBanner([]);
    setFileList([])
  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveConfig = (e) => {
    e.preventDefault();
    setLoading(true);

    let formData = new FormData();
    if(mainconfigs.id)formData.append('id',mainconfigs.id);
    formData.append("title",title);
    formData.append("description",description);
    formData.append("stake",stake);
    formData.append("start_time",startDate);
    formData.append("end_time",endDate);
    formData.append('status', status);

    if(fileList.length>0 &&!fileList[0].isCurrentImg) formData.append('banner', fileList[0].originFileObj);
    let url="octagon/superjackpot/jackpot/";
    if(mainconfigs.id)url=`octagon/superjackpot/jackpot/${mainconfigs.id}/`;

    LModel.updateMultipart(url, formData).then(response => {
      console.log(response);
      if (response.data) {
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
      debugger;
    });
  }

  const createConfig = (e) => {
    e.preventDefault();
    
    if(!title) return message.warning("Please enter title");
    if(!description) return message.warning("Please enter Description");
    if(!stake) return message.warning("Please enter stake");
    if(!startDate) return message.warning("Please enter start date");
    if(!endDate) return message.warning("Please enter end date");
    if(!status) return message.warning("Please enter status");
    
    setLoading(true);
    let formData = new FormData();
    if(mainconfigs.id)formData.append('id',mainconfigs.id);
    formData.append("title",title);
    formData.append("description",description);
    formData.append("stake",stake);
    formData.append("start_time",moment(startDate).format("YYYY-MM-DD hh:mm:ss"));
    formData.append("end_time",moment(endDate).format("YYYY-MM-DD hh:mm:ss"));
    formData.append('status', status);
    const data={title:title,description:description,stake:stake,start_time:startDate,end_time:endDate,status:status};
    if(mainconfigs.id)data.id=mainconfigs.id;
    if(fileList.length>0 &&!fileList[0].isCurrentImg) formData.append('banner', fileList[0].originFileObj);
    let url="octagon/superjackpot/jackpot/";
    if(mainconfigs.id)url=`octagon/superjackpot/jackpot/${mainconfigs.id}/`;

    LModel.createMultipart(url, formData).then(response => {
      console.log(response);
      if (response.data) {
        setMainConfigs(response.data);
        setLoading(false);
        message.success("Jackpot created successfully");
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
      message.error("Something went wrong");
    });
  }

  const refreshConfig = (e) => {

  }

  const onFileChange = ({ fileList: newFileList }) => {
    console.log(newFileList);
    setFileList(newFileList);
  };


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);
  const stringData=description.toString();
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>createNewJackpot()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Jackpot</span>
          </Button>
        </div>
      </div>
      {!loading ? <>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Jackpot Configuration</h5>
              <Form encType="multipart/form">
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Title:</Form.Label>
                      <Form.Control required type="text" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>Status: </Form.Label>
                      <Form.Select id="status" value={status} onChange={(e) => setStatus(e.target.value)} disabled={ mainconfigs.id && mainconfigs.event_choices.length>0 && mainconfigs.prizes.length>0?false:true }>
                        {configOptions.superjackpot_status && configOptions.superjackpot_status.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                
                {/* <hr></hr> */}
                <Row>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="stake" defaultValue={0}>
                      <Form.Label>Stake:</Form.Label>
                      <Form.Control required type="number" value={stake} placeholder="Stake" onChange={(e) => setStake(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="startDate" defaultValue={0}>
                      <Form.Label>Start Date:</Form.Label>
                      <Datetime
                        timeFormat={true}
                        closeOnSelect={false}
                        onChange={setStartDate}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={startDate ? moment(startDate).format("YYYY-MM-DDThh:mm") : ""}
                              placeholder="YYYY-MM-DDThh:mm"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="endDate" defaultValue={0}>
                      <Form.Label>End Date:</Form.Label>
                      <Datetime
                        timeFormat={true}
                        closeOnSelect={false}
                        onChange={setEndDate}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={endDate ? moment(endDate).format("YYYY-MM-DDThh:mm") : ""}
                              placeholder="YYYY-MM-DDThh:mm"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    {/* <Form.Group id="description">
                      <Form.Label>Description:</Form.Label>
                      <Form.Control as="textarea" placeholder="Description"  value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group> */}
                    <h6>Description:</h6>
                    {params.id ?description&&<CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                        // editor.setData( description );
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />:<CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                      // editor.setData( description );
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />}
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>Status:</Form.Label>
                      <Form.Select id="status" value={status} onChange={(e) => setStatus(e.target.value)} >
                        {configOptions.superjackpot_status && configOptions.superjackpot_status.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={12} xl={12}>
                    <h5 className="mb-4">Banner</h5>
                    <Upload
                      listType="picture-card"
                      name="banner"
                      onPreview={handlePreview}
                      onChange={onFileChange}
                      maxCount={1}
                      fileList={fileList}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    </Upload>
                  </Col>
                </Row>
                <hr></hr>
                <div className="mt-3">
                  {mainconfigs.id?<Button variant="primary" type="submit" onClick={(e)=>saveConfig(e)}>Save</Button>:<Button variant="primary" type="submit" onClick={(e)=>createConfig(e)}>Create</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>

        <hr></hr>
       {mainconfigs.id? <Tabs defaultActiveKey="1" type="card">
        <TabPane
          tab={
            <span>
              Choices
            </span>
          }
          key="1"
        >
          <Choices id={params.id||mainconfigs.id} refreshJackpotConfig={getJackpotCongigurations}/>
        </TabPane>
        <TabPane
          tab={
            <span>
              Prices
            </span>
          }
          key="2"
        >
          <Prices id={params.id||mainconfigs.id} refreshJackpotConfig={getJackpotCongigurations}/>
        </TabPane>
        </Tabs>:null}
        
      </> : <Spinner />}

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
