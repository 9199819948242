import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import { Col, Row, Button, Card, Form,Table, InputGroup,Badge,Nav } from '@themesberg/react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Spin,Select,message,Modal,Switch } from 'antd';

import { LoadingOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const { Option } = Select;
const {confirm} = Modal;


export default (props) => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState({});
  const [configOptions, setConfigOptions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentPaymentMethods, setCurrentPaymentMethods] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();

    getCongigOptions();
    getPaymentMethodConfigs();

  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getPaymentMethodConfigs = () => {
    LModel.find(`octagon/mainconfigs/`,null,null).then((response) => {
      if (response.data) {
        // console.log(response.data);
        setMainConfigs(response.data);
        setPaymentMethods(response.data.supported_payment_methods);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data.payment_method_enable_options);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    const paymentMethod = paymentMethods.map(item => {
      if(item.key === currentPaymentMethods.key){
        item=currentPaymentMethods;
      }
      return item;
    });
    // delete data.front_page_images
    // delete data.frontend_single_image
    // delete data.logo
    // delete data.underage_img
    data.supported_payment_methods=paymentMethod;
    LModel.update("octagon/mainconfigs/",data).then(response=>{
      console.log(response);
      if (response.data) {
        console.log(response.data);
        setMainConfigs(response.data);
        getPaymentMethodConfigs();
        message.success('Payment Methods Updated Successfully');

        // setPaymentMethods(response.data.supported_payment_methods);
      }
      setLoading(false);
    }).catch(err => {
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }

  const onConfigChange = (key,value)=>{
    console.log(value);
    const newConfig={...currentPaymentMethods};

    // if(key === "enable_option"){
    //   newConfig[key]=value?3:4;
    // }else 
    newConfig[key]=value;
    console.log(newConfig);
    setCurrentPaymentMethods(newConfig);
  } 
  const getAllEventChoicesConfig = (key) => {
    setLoading(true);
    const currentPaymentMethod= paymentMethods.find(item=>key==item.key);
    // console.log(currentPaymentMethod,paymentMethods,key);
    if(currentPaymentMethod){
      setCurrentPaymentMethods(currentPaymentMethod);
      setLoading(false);
    }else setLoading(false);
  }
  const reset =()=>{
    setCurrentPaymentMethods({});
  }

  const TableRow = (props) => {
    const { pmKey, name, enable_option,num } = props;
    const status= configOptions.find(item=>item[0]==enable_option);
    console.log(status?status[1]:"");
    return (
      <tr>
        <td>
          <Nav.Link href="#" className="text-primary fw-bold">{num}</Nav.Link>
        </td>
        {/* <td>{pmKey}</td> */}
        <td>{name}</td>
        <td>{status?status[1]:""}</td>
        {/* <td>{enable_option==3?<Badge bg="success" className="badge-lg">Yes</Badge> :<Badge bg="secondary" className="badge-lg">No</Badge>}</td> */}
        <td>
          <Row span={12} >
            <Col md={5} lg={5}>
            <Nav.Link onClick={()=>getAllEventChoicesConfig(pmKey)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Nav.Link>
            </Col>
            {/* <Col md={5} lg={5}>
            <Nav.Link onClick={()=>deleteJackpotChoice(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faTrash}  /> Delete </div></Nav.Link>
            </Col> */}
          </Row>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <div className="d-flex">
            <Button 
            onClick={()=>reset()}
            variant="secondary" 
            className="text-dark me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>New Method</span>
            </Button>
          </div> */}
      </div>
      {<>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Payment Methods</h5>
              <Form>
                <Row>
                  {/* <Col md={4} className="mb-3">
                  <Form.Group id="result" defaultValue={0}>
                      <Form.Label>Key:</Form.Label>
                      <Form.Control required type="text" defaultValue={currentPaymentMethods.key} onChange={(e) => onConfigChange('key',e.target.value)} />
                    </Form.Group>
                  </Col> */}
                  <Col md={4} className="mb-3">
                    <Form.Group id="order">
                      <Form.Label>Name:</Form.Label>
                      <Form.Control required type="text" defaultValue={currentPaymentMethods.name} onChange={(e) => onConfigChange('name',e.target.value)} />
                    </Form.Group>
                  </Col>
                {/* </Row>
                <Row>
                <Col md={6} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Enable:</Form.Label>
                      <Switch checked={currentPaymentMethods.enable_option===3}  onChange={(value)=>onConfigChange('enable_option',value)} />
                    </Form.Group>
                  </Col> */}
                  <Col lg={4} md={4} >
                    <Form.Group id="jackpot">
                      <Form.Label>Allowed Option:</Form.Label>
                      <Form.Select id="jackpot" value={currentPaymentMethods.enable_option} onChange={(e) => onConfigChange('enable_option',parseInt(e.target.value))}>
                        {configOptions && configOptions.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-3">
                  {!currentPaymentMethods.key?null:<Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>
        
      </>}
      {!loading ? <>
              <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                  <Table responsive className="table-centered table-nowrap rounded mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-0">#</th>
                        {/* <th className="border-0">Key</th> */}
                        <th className="border-0">Name</th>
                        <th className="border-0">Active</th>
                        {/* <th className="border-0">Is Reserved</th> */}
                        {/* <th className="border-0">Jackpot</th> */}
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentMethods.map((pm,idx) => <TableRow key={`jackpot-${pm.key}`} {...pm} pmKey={pm.key}  num={idx+1}/>)}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              
            </> : <Spinner />}
    </>
  );
};
